import { useGetFilterableBranches } from '@shared/api/queries/branches/branches';
import { LoadingAnimation, Select } from '@uag/react-core';
import { useTranslation } from 'react-i18next';


type Props = {
    value: string | null | undefined;
    onChange: (value: string | undefined) => void;
};

export const BranchCombobox = ({ value, onChange: handleOnChange }: Props) => {
    const { t } = useTranslation();
    const { data: apiBranches, isLoading: areBranchesLoading } = useGetFilterableBranches();
    const branches = apiBranches?.data?.map((branch) => ({ value: branch.id, label: branch.name })) ?? [];

    if (areBranchesLoading) {
        return (
            <div className="p-base">
                <LoadingAnimation />
            </div>
        );
    }
    return (
        <Select
            data={[...branches]}
            label={t('branch')}
            value={value}
            allowDeselect
            clearable
            searchable
            onChange={(value) => handleOnChange(value ? value : undefined)}
        />
    );
};
