import { useGetRemarks } from '@shared/api/queries/remarks/remarks';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { useRequestContext } from './RequestProvider';

export const Remarks = () => {
    const { request } = useRequestContext();

    const { data: remarks, isLoading } = useGetRemarks(request.id);
    const { t } = useTranslation();

    if (!remarks || !remarks.data || remarks.data.length === 0) {
        return null;
    }

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <div>
            <div>{t('remarks')}</div>
            <div className="text-sm border border-border-disabled bg-background-gray-ultra-light p-4">
                {remarks.data.map((remark) => (
                    <div key={remark.id}>{remark.text}</div>
                ))}
            </div>
        </div>
    );
};
