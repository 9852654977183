/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type MemberType = typeof MemberType[keyof typeof MemberType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MemberType = {
  Member: 'Member',
  Owner: 'Owner',
} as const;
