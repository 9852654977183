import { useDebouncedValue } from '@mantine/hooks';
import { OnboardingRequestModel, OnboardingRequestModelCurrentState, RequestStateType } from '@shared/api/models';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import {
    BaseLayout,
    ErrorView,
    getExceptionDetails,
    Inbox,
    InboxGroup,
    InboxItem,
    LoadingAnimation,
    Select
} from '@uag/react-core';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { managementInstance } from 'i18n';
import { BranchCombobox } from 'shared';
import { ResponsiblePersonCombobox } from 'shared/components/ResponsiblePersonCombobox';
import {
    stateFilterAtom,
    appliedStateFilterAtom,
    branchFilterAtom,
    appliedBranchFilterAtom,
    responsiblePersonFilterAtom,
    appliedResponsiblePersonFilterAtom,
    searchTextAtom
} from './RequestAtoms';
import { RequestsHeader } from './RequestsHeader';

// eslint-disable-next-line no-duplicate-imports
import type { InboxItemType } from '@uag/react-core';

export const RequestsBaseRoute = '/requests';

const getStatus = (state: OnboardingRequestModelCurrentState | undefined) => {
    switch (state) {
        case RequestStateType.Invited:
        case RequestStateType.DataInput:
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.ContractSigning:
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
            return undefined;
        case RequestStateType.SignatureVerification:
        case RequestStateType.DataVerification:
            return 'warning';
        case RequestStateType.Completed:
            return 'success';
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return 'alert';
        default:
            throw new Error(`Unknown state: ${state}`);
    }
};

type OnboardingInboxItem = InboxItemType & {
    id: string;
};

const getInboxItem = (data: OnboardingRequestModel): OnboardingInboxItem => ({
    id: data.id,
    name: data.dokaOnboardingResponsible?.model.displayName,
    img: data.dokaOnboardingResponsible?.model.profilePictureUri ?? undefined,
    company: data.companyData.companyName!,
    place: data.branch?.name ?? undefined,
    time: new Date(data.createdDate),
    status: {
        status: getStatus(data.currentState),
        message: (data.currentState && managementInstance.t(`requestStateType.${data.currentState}`)) ?? ''
    }
});

const getStateFilterValues = () => {
    return Object.values(RequestStateType).map((state) => ({
        value: state,
        label: managementInstance.t(`requestStateType.${state}`)
    }));
};

export const Requests = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();
    const [searchText, setSearchText] = useAtom(searchTextAtom);
    const [debounceSearchText] = useDebouncedValue(searchText, 500);

    const [stateFilter, setStateFilter] = useAtom(stateFilterAtom);
    const [appliedStateFilter, setAppliedStateFilter] = useAtom(appliedStateFilterAtom);

    const [branchFilter, setBranchFilter] = useAtom(branchFilterAtom);
    const [appliedBranchFilter, setAppliedBranchFilter] = useAtom(appliedBranchFilterAtom);

    const [responsiblePersonFilter, setResponsiblePersonFilter] = useAtom(responsiblePersonFilterAtom);
    const [appliedResponsiblePersonFilter, setAppliedResponsiblePersonFilter] = useAtom(
        appliedResponsiblePersonFilterAtom
    );

    const { data, isLoading, error } = useGetRequests(
        {
            state: appliedStateFilter ?? undefined,
            customerSearchText: debounceSearchText,
            branchIds: appliedBranchFilter ? [appliedBranchFilter] : undefined,
            contributorIds: appliedResponsiblePersonFilter ? [appliedResponsiblePersonFilter] : undefined
        },
        { query: { refetchOnWindowFocus: true } }
    );

    const filteredItems =
        data?.data.filter((item) => !!appliedStateFilter || item.currentState !== RequestStateType.DataInput) ?? [];

    const completedItems = filteredItems.filter(
        (item) => item.currentState === RequestStateType.Completed || item.currentState === RequestStateType.Declined
    );

    const ongoingItems = filteredItems.filter((item) => !completedItems.includes(item));

    const itemGroups = [
        { title: t('ongoing'), items: ongoingItems },
        { title: t('completed'), items: completedItems }
    ];

    const StateFilter = (
        <Select
            key="stateFilter"
            data={getStateFilterValues()}
            label={t('requestState')}
            placeholder="Pick value"
            value={stateFilter}
            allowDeselect
            clearable
            searchable
            onChange={(value) => setStateFilter(value as RequestStateType)}
        />
    );

    const BranchFilter = (
        <BranchCombobox key="branchFilter" value={branchFilter} onChange={(value) => setBranchFilter(value)} />
    );

    const ResponsiblePersonFilter = (
        <ResponsiblePersonCombobox
            key="responsiblePersonFilter"
            value={responsiblePersonFilter}
            onChange={(value) => setResponsiblePersonFilter(value)}
        />
    );

    const handleFilterModalClosed = (applyFilters: boolean) => {
        if (applyFilters) {
            setAppliedStateFilter(stateFilter);
            setAppliedBranchFilter(branchFilter);
            setAppliedResponsiblePersonFilter(responsiblePersonFilter);
        } else {
            setStateFilter(appliedStateFilter);
            setBranchFilter(appliedBranchFilter);
            setResponsiblePersonFilter(appliedResponsiblePersonFilter);
        }
    };

    const handleClearFilters = () => {
        setStateFilter(null);
        setBranchFilter(null);
        setResponsiblePersonFilter(null);
    };

    const openItem = (item: OnboardingInboxItem) => {
        navigate(`${RequestsBaseRoute}/${item.id}`);
    };

    if (filteredItems && filteredItems.length > 0 && location.pathname.endsWith(RequestsBaseRoute)) {
        navigate(`${RequestsBaseRoute}/${filteredItems[0].id}`);
    }

    return (
        <BaseLayout versionNumber={APP_VERSION}>
            {error && <ErrorView description={getExceptionDetails(error)?.message} />}
            {!error && (
                <>
                    <Inbox
                        filterModalTitle={t('filterRequests')}
                        filters={[StateFilter, BranchFilter, ResponsiblePersonFilter]}
                        filtersSet={!!appliedStateFilter || !!appliedBranchFilter || !!appliedResponsiblePersonFilter}
                        header={<RequestsHeader />}
                        searchText={searchText}
                        onClearFilters={handleClearFilters}
                        onFilterModalClose={handleFilterModalClosed}
                        onSearchTextChange={(value) => setSearchText(value)}
                    >
                        {isLoading && <LoadingAnimation />}
                        <div className="inbox-entries">
                            {!isLoading &&
                                itemGroups.map((group) => (
                                    <InboxGroup key={group.title} title={group.title}>
                                        {group.items.map((item) => (
                                            <InboxItem
                                                key={item.id}
                                                data={getInboxItem(item)}
                                                isSelected={location.pathname.endsWith(item.id)}
                                                onClick={openItem}
                                            />
                                        ))}
                                    </InboxGroup>
                                ))}
                        </div>
                    </Inbox>
                    <div className="w-full p-base">{!isLoading && <Outlet />}</div>
                </>
            )}
        </BaseLayout>
    );
};
