import { Button, TextInput, Table, ActionIcon } from '@mantine/core';
import { TeamModel, TeamMemberModel, MemberType } from '@shared/api/models';
import { useGetMyTeams, useGetTeamMembers } from '@shared/api/queries/teams/teams';
import { getInitials } from '@shared/utils';
import { useQueryClient } from '@tanstack/react-query';
import { BaseLayout, Icon, variants, SkeletonTable, Select, Pagination, getPagingParams } from '@uag/react-core';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { currentContributorAtom } from 'app/atoms';
import { openDeleteModal } from './modals/DeleteModal';
import { openEditModal } from './modals/EditModal';
import { openInviteModal } from './modals/InviteModal';

// eslint-disable-next-line no-duplicate-imports
import type { ItemsPerPageType } from '@uag/react-core';

export const Teams = () => {
    const { id } = useParams<{ id: string }>();
    const currentContributor = useAtomValue(currentContributorAtom);

    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data: myTeams, isLoading: isLoadingTeams } = useGetMyTeams();

    const [selectedTeamId, setSelectedTeamId] = useState<string>(id || '');
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [currentPage, setCurrentPage] = useState(1);

    if (myTeams?.data && myTeams.data.length > 0 && !selectedTeamId) {
        setSelectedTeamId(myTeams.data[0].id || '');
    }

    const {
        data: teamMembers,
        isLoading: isLoadingMembers,
        queryKey
    } = useGetTeamMembers(selectedTeamId, { ...getPagingParams(currentPage, itemsPerPage) });

    const totalPages =
        teamMembers && teamMembers.metadata?.paging?.totalCount
            ? Math.ceil(teamMembers.metadata.paging.totalCount / itemsPerPage)
            : 1;

    const isLoading = isLoadingTeams || isLoadingMembers;

    const isTeamManager =
        teamMembers?.data?.length &&
        teamMembers.data.some(
            (member: TeamMemberModel) => member.memberType === 'Owner' && member.id === currentContributor.id
        );

    const handleInviteClick = () => {
        openInviteModal({
            title: t('inviteCollaborators'),
            acceptButtonText: t('sendInvitation'),
            teamId: selectedTeamId
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const handleEditClick = (memberName: string, memberRole: MemberType, contributorId: string) => {
        openEditModal({
            title: t('editTeamMember'),
            acceptButtonText: t('saveChanges'),
            memberName,
            teamId: selectedTeamId,
            memberType: memberRole,
            contributorId
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const handleDeleteClick = (contributorId: string, memberName: string) => {
        openDeleteModal({
            title: `${t('removeCollaborator')}?`,
            acceptButtonText: t('confirmRemoval'),
            memberName,
            teamId: selectedTeamId,
            contributorId
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const teamOptions = myTeams?.data?.map((team: TeamModel) => ({
        value: team.id,
        label: team.name
    }));

    return (
        <BaseLayout>
            <div className="flex flex-col gap-base pb-base">
                <span className="text-3xl font-default font-semibold leading-3xl">{t('responsibleTeam')}</span>
                <div className="grid grid-cols-2">
                    {!isLoadingTeams && teamOptions?.length && teamOptions.length > 1 && (
                        <Select
                            className="w-fit justify-start"
                            data={teamOptions}
                            value={selectedTeamId}
                            searchable
                            onChange={(value) => setSelectedTeamId(value ?? '')}
                        />
                    )}
                    <div className="col-start-2 flex justify-end gap-2">
                        <TextInput
                            leftSection={<Icon icon="search" />}
                            placeholder={`${t('search')}`}
                            variant={variants.textField.iconDecoration}
                        />
                        {isTeamManager && (
                            <Button onClick={handleInviteClick}>
                                <span>{t('inviteCollaborators')}</span>
                                <Icon className="ml-base" icon="person_add" />
                            </Button>
                        )}
                    </div>
                </div>
                {isLoading && <SkeletonTable columns={3} rows={5} />}
                {!isLoading && (
                    <>
                        <Table>
                            <Table.Thead>
                                <Table.Tr className="border-b-2 border-border-default">
                                    <Table.Th className="font-semibold">{t('user', 'User')}</Table.Th>
                                    <Table.Th>{t('memberType')}</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {teamMembers?.data?.map((member: TeamMemberModel) => (
                                    <Table.Tr key={member.email} className="px-0 py-2">
                                        <Table.Td>
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="h-8 w-8 overflow-hidden rounded-full">
                                                    {member.profilePictureUri && (
                                                        <img
                                                            alt="Avatar"
                                                            className="h-full w-full object-cover"
                                                            src={member.profilePictureUri ?? ''}
                                                        />
                                                    )}
                                                    {!member.profilePictureUri && (
                                                        <div className="avatar avatar-placeholder">
                                                            {getInitials(member.displayName)}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <span className="data-table-cell-content m-0 p-0 font-default font-normal leading-base text-text-default">
                                                        {member.firstName} {member.lastName}
                                                    </span>
                                                    <span className="text-base data-table-cell-content p-0 font-default font-normal leading-base text-text-light">
                                                        {member.email}
                                                    </span>
                                                </div>
                                            </div>
                                        </Table.Td>
                                        <Table.Td>
                                            <div className="data-table-cell-content">{member.memberType}</div>
                                        </Table.Td>
                                        <Table.Td>
                                            {isTeamManager && (
                                                <div className="flex flex-row items-center justify-end gap-1">
                                                    <ActionIcon
                                                        onClick={() =>
                                                            handleEditClick(
                                                                member.displayName,
                                                                member.memberType,
                                                                member.id
                                                            )
                                                        }
                                                    >
                                                        <Icon className="text-icon-accent" icon="edit" />
                                                    </ActionIcon>
                                                    <ActionIcon
                                                        onClick={() => handleDeleteClick(member.id, member.displayName)}
                                                    >
                                                        <Icon className="text-icon-accent" icon="delete" />
                                                    </ActionIcon>
                                                </div>
                                            )}
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                        <Pagination
                            className="mt-8"
                            itemsPerPage={itemsPerPage}
                            page={currentPage}
                            totalPages={totalPages}
                            onItemsPerPageChange={setItemsPerPage}
                            onPageChange={setCurrentPage}
                        />
                    </>
                )}
            </div>
        </BaseLayout>
    );
};
