import { useGetCurrentContributor } from '@shared/api/queries/contributors/contributors';
import { ErrorLayout, LoadingAnimation } from '@uag/react-core';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { currentContributorAtom } from './atoms';

export const Frame = () => {
    const { isLoading, data: currentContributor } = useGetCurrentContributor();
    const setCurrentContributor = useSetAtom(currentContributorAtom);
    const { t } = useTranslation();

    if (isLoading) {
        return <LoadingAnimation />;
    }

    if (currentContributor) {
        setCurrentContributor(currentContributor.data);
    } else {
        return <ErrorLayout description={t('errorLoadingCurrentContributor')} />;
    }

    return <Outlet />;
};
