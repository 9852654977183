import { isValidElement, ReactElement } from 'react';

import { RequestActionButtonProps } from './actions/types';
import { useRequestContext } from './RequestProvider';

type Props = {
    children: ReactElement<RequestActionButtonProps> | ReactElement<RequestActionButtonProps>[];
};

export const RequestActions = ({ children: propChildren }: Props) => {
    const children = Array.isArray(propChildren) ? propChildren : [propChildren];
    const { request } = useRequestContext();

    const actionsToShow = children.filter(
        (child) =>
            isValidElement(child) &&
            request.currentState &&
            (child.props.visibleInStates === request.currentState ||
                child.props.visibleInStates?.includes(request.currentState))
    );

    return (
        <div className="flex w-full flex-wrap items-center justify-end gap-base" style={{ minWidth: 'content' }}>
            {actionsToShow}
        </div>
    );
};
