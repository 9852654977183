import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CompanyForm } from '@shared/components/company/CompanyForm/CompanyForm';
import { Icon, variants } from '@uag/react-core';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { currentContributorAtom } from 'app/atoms';
import { useRequestContext } from '../RequestProvider';
import { checkForDokaResponsibleChange } from './functions';
import { RequestActionButtonProps } from './types';

export const EditModeAction = (_: RequestActionButtonProps) => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);
    const { request } = useRequestContext();
    const currentContributor = useAtomValue(currentContributorAtom);

    const handleOpen = async () => {
        if (await checkForDokaResponsibleChange(request, currentContributor)) {
            return;
        }

        open();
    };

    return (
        <>
            <Modal className="w-[960px]" opened={opened} title={t('editCompanyData')} onClose={close}>
                <CompanyForm mode="Backoffice" request={request} onClose={close} />
            </Modal>
            <Button variant={variants.button.secondary} onClick={handleOpen}>
                {t('edit')}
                <Icon className="ml-base" icon="edit" />
            </Button>
        </>
    );
};
