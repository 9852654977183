import { Button, Modal } from '@mantine/core';
import { MemberType } from '@shared/api/models';
import { useAddTeamMember } from '@shared/api/queries/teams/teams';
import { variants, Select, toastNotifications, getExceptionDetails } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

type EditModalProps = {
    title: string;
    teamId: string;
    acceptButtonText: string;
    memberName: string;
    memberType: MemberType;
    contributorId: string;
} & InstanceProps<string>;

const selectItems = ['Member', 'Owner'];

const EditModal = ({
    title,
    teamId,
    acceptButtonText,
    memberType,
    memberName,
    contributorId,
    isOpen,
    onResolve,
    onReject
}: EditModalProps) => {
    const [selectedValue, setSelectedValue] = useState<MemberType>(memberType);
    const { t } = useTranslation();
    const { mutateAsync: addTeamMember, isPending } = useAddTeamMember();

    const handleSelectChange = (value: string | null) => {
        setSelectedValue(value as MemberType);
    };
    const submit = async () => {
        try {
            await addTeamMember({ id: teamId, data: { contributorId, type: selectedValue } });
            onResolve(selectedValue);
        } catch (err) {
            toastNotifications.error({
                title: t('unknownError'),
                message: t('unknownErrorDescription', {
                    reason: getExceptionDetails(err)?.message
                })
            });
        }
    };
    const handleReject = () => {
        onReject();
    };

    return (
        <Modal opened={isOpen} title={title} onClose={handleReject}>
            <div className="flex w-[560px] flex-col gap-6">
                <div className="flex flex-col items-start gap-2">
                    <span className="text-base w-full font-normal leading-base text-text-ultra-light">
                        {t(
                            'editTeamMemberText',
                            `Please select which permission the user ${memberName} should have in the future when managing company requests for our digital services for [country].`
                        )}
                    </span>
                    <Select className="w-full" data={selectItems} value={selectedValue} onChange={handleSelectChange} />
                </div>
                <div className="flex flex-row justify-end gap-x-base">
                    <Button variant={variants.button.secondary} onClick={handleReject}>
                        {t('cancel')}
                    </Button>
                    <Button disabled={!selectedValue} loading={isPending} onClick={submit}>
                        {acceptButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export const openEditModal = create(EditModal);
