import { Button, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useCreateInvitationForNewCustomer } from '@shared/api/queries/invitations/invitations';
import { getGetRequestsQueryKey } from '@shared/api/queries/requests/requests';
import { CompanyBaseData } from '@shared/components/company/CompanyForm/CompanyBaseData';
import { companyToInvite } from '@shared/components/company/CompanyForm/companyFormMappings';
import { invitationSchema, InvitationSchemaType } from '@shared/components/company/CompanyForm/companyFormSchema';
import { useQueryClient } from '@tanstack/react-query';
import { toastNotifications, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

type Props = {
    onClose: (requestId: string) => void;
    onBack: () => void;
};

export const InviteNewCompany = ({ onClose, onBack: handleBack }: Props) => {
    const { t } = useTranslation();

    const form = useForm<InvitationSchemaType>({
        mode: 'controlled',
        validate: zodResolver(invitationSchema),
        validateInputOnChange: true,
        initialValues: {
            companyData: {
                city: '',
                companyName: '',
                country: '',
                generalOfficeMail: '',
                industrialSector: '',
                phoneNumber: '',
                uidNumber: '',
                zipCode: '',
                street: ''
            },
            companyRegistrar: ''
        }
    });

    const { onSubmit: onSubmitForm, isValid, resetDirty } = form;
    const queryClient = useQueryClient();

    const { mutateAsync: inviteCompany, isPending: isInviteCompanyPending } = useCreateInvitationForNewCustomer();

    const isLoading = isInviteCompanyPending;

    const handleSubmit = async (values: InvitationSchemaType) => {
        try {
            const result = await inviteCompany({
                data: { ...companyToInvite(values), invitee: { model: { email: values.companyRegistrar } } }
            });

            resetDirty();
            queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
            toastNotifications.sucess({ title: t('success'), message: t('invited', { data: t('company') }) });
            onClose(result.data!.id);
        } catch {
            toastNotifications.error({ title: t('failed'), message: t('notInvited', { data: t('company') }) });
        }
    };

    return (
        <form className="flex w-full flex-col gap-4" onSubmit={onSubmitForm(handleSubmit)}>
            <div className="text-text-light">{t('inviteCompanyHeader')}</div>
            <CompanyBaseData form={form} />

            <h4 className="text-base-bold -mb-2 mt-6">{t('keyUser')}</h4>
            <TextInput
                description={t('companyRegistrarDescription')}
                label={t('companyRegistrar')}
                placeholder={t('enterPlaceholder', { label: t('companyRegistrar') })}
                required
                {...form.getInputProps('companyRegistrar')}
            />

            <div className="modal-action-section mt-2">
                <Button loading={isLoading} variant={variants.button.secondary} onClick={handleBack}>
                    {t('back')}
                </Button>
                <Button disabled={!isValid()} loading={isLoading} type="submit">
                    {t('submit')}
                </Button>
            </div>
        </form>
    );
};
