import { OnboardingRequestDetailModel } from '@shared/api/models';
import { createContext, useContext } from 'react';

type RequestProviderProps = {
    children: React.ReactNode;
} & RequestContextProps;

type RequestContextProps = {
    request: OnboardingRequestDetailModel;
};

const RequestContext = createContext<RequestContextProps | undefined>(undefined);

export const RequestProvider = ({ children, request }: RequestProviderProps) => {
    return <RequestContext.Provider value={{ request }}>{children}</RequestContext.Provider>;
};

export const useRequestContext = () => {
    const context = useContext(RequestContext);

    if (!context) {
        throw new Error('useRequestContext must be used within RequestProvider');
    }

    return context;
};
