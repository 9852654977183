import { Button } from '@mantine/core';
import {
    getGetRequestDetailQueryKey,
    getGetRequestsQueryKey,
    useVerifyRequest
} from '@shared/api/queries/requests/requests';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, Icon, toastNotifications } from '@uag/react-core';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { currentContributorAtom } from 'app/atoms';
import { useRequestContext } from '../RequestProvider';
import { checkForDokaResponsibleChange } from './functions';
import { RequestActionButtonProps } from './types';

export const ApproveRequestAction = (_: RequestActionButtonProps) => {
    const { t } = useTranslation();
    const { mutateAsync: verifyRequest, isPending: isVerifyPending } = useVerifyRequest();
    const queryClient = useQueryClient();
    const { request } = useRequestContext();
    const currentContributor = useAtomValue(currentContributorAtom);

    const handleApprove = async () => {
        if (await checkForDokaResponsibleChange(request, currentContributor)) {
            return;
        }

        try {
            await verifyRequest({ id: request.id, data: { verifyState: 'Accept' } });
            toastNotifications.sucess({
                title: t('success'),
                message: t('requestActionSuccess', { action: t('approved') })
            });
        } catch (error) {
            toastNotifications.error({
                title: t('failed'),
                message: t('requestActionFailed', {
                    action: t('approved'),
                    reason: getExceptionDetails(error)?.message
                })
            });
        }
        queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(request.id) });
        queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
    };

    return (
        <Button loading={isVerifyPending} type="submit" onClick={handleApprove}>
            {t('approveCustomer')}
            <Icon className="ml-base" icon="check" />
        </Button>
    );
};
