import { RequestStateType } from '@shared/api/models';
import { atomWithStorage } from 'jotai/utils';

export const searchTextAtom = atomWithStorage<string>('searchText', '');

export const stateFilterAtom = atomWithStorage<RequestStateType | null>('stateFilter', null);
export const appliedStateFilterAtom = atomWithStorage<RequestStateType | null>('appliedStateFilter', null);

export const branchFilterAtom = atomWithStorage<string | null>('branchFilter', null);
export const appliedBranchFilterAtom = atomWithStorage<string | null>('appliedBranchFilter', null);

export const responsiblePersonFilterAtom = atomWithStorage<string | null>('responsiblePersonFilter', null);
export const appliedResponsiblePersonFilterAtom = atomWithStorage<string | null>('appliedResponsiblePerson', null);
