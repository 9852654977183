import { Button } from '@mantine/core';
import { useVerifyContractSignature } from '@shared/api/queries/contracts/contracts';
import { getGetRequestDetailQueryKey, getGetRequestsQueryKey } from '@shared/api/queries/requests/requests';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, Icon, toastNotifications } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { useRequestContext } from '../RequestProvider';
import { RequestActionButtonProps } from './types';

export const ActivateCustomerAction = (_: RequestActionButtonProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { request } = useRequestContext();

    const { mutateAsync: verifySignature, isPending: isVerifyPending } = useVerifyContractSignature();

    const handleApprove = async () => {
        try {
            await verifySignature({ id: request.id, data: { verifyState: 'Accept' } });
            toastNotifications.sucess({
                title: t('success'),
                message: t('customerActivated')
            });
        } catch (error) {
            toastNotifications.error({
                title: t('failed'),
                message: t('customerActivationFailed', {
                    action: t('approved'),
                    reason: getExceptionDetails(error)?.message
                })
            });
        }
        queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(request.id) });
        queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
    };

    return (
        <Button loading={isVerifyPending} type="submit" onClick={handleApprove}>
            {t('activateCustomer')}
            <Icon className="ml-base" icon="check" />
        </Button>
    );
};
