import { SelectProps } from '@mantine/core';
import { ContributorModel } from '@shared/api/models';
import { getInitials } from '@shared/utils';
import { clsx } from 'clsx';

export const getContributorOption = (contributor: ContributorModel) => ({
    label: contributor.displayName ? contributor.displayName : contributor.email,
    value: contributor.id,
    displayName: contributor.displayName,
    email: contributor.email,
    picture: contributor.profilePictureUri
});

export const renderContributorOption: SelectProps['renderOption'] = ({ option }) => {
    const email = 'email' in option ? (option.email as string) : '';
    const displayName = 'displayName' in option ? (option.displayName as string) : undefined;
    const picture = 'picture' in option ? (option.picture as string) : undefined;
    const initials = getInitials(displayName ? displayName : email.split('@').join(' ')).join('');

    return (
        <div className="flex flex-row items-center gap-base py-1">
            <div className={clsx('avatar', { ['avatar-placeholder']: !picture })}>
                {!picture && <span>{initials}</span>}
                {picture && <img alt={option.label?.toString()} src={picture} />}
            </div>
            <div>
                <div>{option.label}</div>
                <div className="text-sm text-text-light">{email}</div>
            </div>
        </div>
    );
};
