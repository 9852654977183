import { Button } from '@mantine/core';
import { Icon, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { openInviteCompanyModal } from './InviteCompany/InviteCompanyModal';

export const RequestsHeader = () => {
    const { t } = useTranslation();

    const handleInviteCompany = () => {
        openInviteCompanyModal();
    };

    return (
        <div className="flex justify-between">
            <div className="text-3xl">{t('requests')}</div>

            <Button variant={variants.button.secondary} onClick={handleInviteCompany}>
                {t('inviteCompany')}
                <Icon className="ml-2" icon="add" />
            </Button>
        </div>
    );
};
