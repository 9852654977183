import { Button, Modal } from '@mantine/core';
import { variants } from '@uag/react-core';
import { Trans, useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

type Props = {
    currentResponsible: string;
} & InstanceProps<void>;

const ChangeResponsibleModal = ({ currentResponsible, isOpen, onResolve, onReject }: Props) => {
    const { t } = useTranslation();

    const submit = () => onResolve();
    const reject = () => onReject();

    return (
        <Modal opened={isOpen} size="lg" title={t('manageRequest')} onAbort={reject} onClose={reject}>
            <div className="modal-description">
                <Trans i18nKey="managedByOther" values={{ currentResponsible }} />
            </div>
            <div className="modal-action-section mt-2xl">
                <Button className="modal-action" variant={variants.button.secondary} onClick={reject}>
                    {t('cancel')}
                </Button>
                <Button className="modal-action" onClick={submit}>
                    {t('continue')}
                </Button>
            </div>
        </Modal>
    );
};

export const openChangeResponsibleModal = create(ChangeResponsibleModal);
