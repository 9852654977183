import { Button, Modal, Textarea } from '@mantine/core';
import { variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

type RemarkModalProps = {
    title: string;
    acceptButtonVariant?: keyof typeof variants.button;
    acceptButtonText: string;
} & InstanceProps<string>;

const RemarkModal = ({
    title,
    acceptButtonVariant,
    acceptButtonText,
    isOpen,
    onResolve,
    onReject
}: RemarkModalProps) => {
    const { t } = useTranslation();
    const [reason, setReason] = useState<string | undefined>();

    const submit = () => onResolve(reason);
    const reject = () => onReject(reason);

    return (
        <Modal opened={isOpen} size="lg" title={title} onAbort={reject} onClose={reject}>
            <Textarea
                classNames={{ input: 'h-28' }}
                label={t('reason')}
                value={reason}
                required
                onChange={(ev) => {
                    setReason(ev.target.value);
                }}
            />
            <div className="modal-action-section mt-2xl">
                <Button className="modal-action" variant={variants.button.secondary} onClick={reject}>
                    {t('cancel')}
                </Button>
                <Button className="modal-action" disabled={!reason} variant={acceptButtonVariant} onClick={submit}>
                    {acceptButtonText}
                </Button>
            </div>
        </Modal>
    );
};

export const openRemarkModal = create(RemarkModal);
