import { Button, Modal } from '@mantine/core';
import { CustomerSelection, CustomerType } from '@shared/components/customer/customerSelection';
import { variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

import { InviteExistingCompany } from './InviteExistingCompany';
import { InviteNewCompany } from './InviteNewCompany';

type Props = InstanceProps<{ requestId?: string }>;

type State = 'CustomerSelection' | 'NewCustomer' | 'ExistingCustomer';

const InviteCompanyModal = ({ isOpen, onResolve, onReject }: Props) => {
    const { t } = useTranslation();
    const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.Existing);
    const [customerNumber, setCustomerNumber] = useState<string>('');

    const submit = (requestId?: string) => onResolve({ requestId });
    const reject = () => onReject();

    const [state, setState] = useState<State>('CustomerSelection');

    const handleCustomerTypeChanged = (type: CustomerType) => setCustomerType(type);

    const handleContinue = () => {
        if (customerType === CustomerType.Existing) {
            setState('ExistingCustomer');
        } else {
            setState('NewCustomer');
        }
    };

    const handleBack = () => {
        setCustomerNumber('');
        setState('CustomerSelection');
    };

    return (
        <Modal
            opened={isOpen}
            size={state === 'NewCustomer' ? '60rem' : '50rem'}
            title={t('inviteCompany')}
            onAbort={reject}
            onClose={reject}
        >
            {state === 'ExistingCustomer' && (
                <InviteExistingCompany customerNumber={customerNumber} onBack={handleBack} onClose={submit} />
            )}
            {state === 'NewCustomer' && <InviteNewCompany onBack={handleBack} onClose={submit} />}
            {state === 'CustomerSelection' && (
                <>
                    <div className="flex flex-col gap-6">
                        <CustomerSelection
                            customerType={customerType}
                            onCustomerNumberChange={setCustomerNumber}
                            onCustomerTypeChanged={handleCustomerTypeChanged}
                        />
                    </div>
                    <div className="modal-action-section mt-2xl">
                        <Button className="modal-action" variant={variants.button.secondary} onClick={reject}>
                            {t('cancel')}
                        </Button>
                        <Button
                            className="modal-action"
                            disabled={customerType === CustomerType.Existing && !customerNumber}
                            onClick={handleContinue}
                        >
                            {t('continue')}
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export const openInviteCompanyModal = create(InviteCompanyModal);
