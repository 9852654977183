import { ContributorDetailModel, OnboardingRequestDetailModel } from '@shared/api/models';

import { openChangeResponsibleModal } from '../modals/ChangeResponsibleModal';

/*
 * Checks if the current user is responsible for the request and opens a modal to change the responsible if not.
 * @param request The request to check the responsible for.
 * @param currentUser The email of the current user.
 * @returns True if the action should be aborted (user clicked cancel in the modal), false if the action can be continued.
 */

export const checkForDokaResponsibleChange = async (
    request: OnboardingRequestDetailModel,
    currentContributor: ContributorDetailModel
) => {
    const responsibleName = request.dokaOnboardingResponsible?.model.displayName;

    if (
        !request.dokaOnboardingResponsible ||
        !responsibleName ||
        request.dokaOnboardingResponsible.model.id === currentContributor.id
    ) {
        return false;
    }

    try {
        await openChangeResponsibleModal({ currentResponsible: responsibleName });
        return false;
    } catch {
        return true;
    }
};
