import { Button, ComboboxItem, Modal } from '@mantine/core';
import { MemberType } from '@shared/api/models';
import { useGetContributors } from '@shared/api/queries/contributors/contributors';
import { useAddTeamMember } from '@shared/api/queries/teams/teams';
import { LoadingAnimation, variants, Select, toastNotifications, getExceptionDetails } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

import { getContributorOption, renderContributorOption } from 'shared/components/ContributorSelectOption';

type InviteModalProps = {
    title: string;
    acceptButtonText: string;
    teamId: string;
} & InstanceProps<void>;

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InviteModal = ({ title, teamId, acceptButtonText, isOpen, onResolve, onReject }: InviteModalProps) => {
    const [selectedContributor, setSelectedContributor] = useState('');
    const [selectedMemberType, setSelectedMemberType] = useState<MemberType>(MemberType.Member);
    const [searchText, setSearchText] = useState('');

    const { t } = useTranslation();

    const { data: contributors, isLoading: areContributorsLoading } = useGetContributors();
    const { mutateAsync, isPending } = useAddTeamMember();

    const handleReject = () => {
        onReject();
    };

    const contributorsList: ComboboxItem[] =
        contributors?.data
            ?.filter(
                (contributor) =>
                    !searchText ||
                    contributor.displayName.includes(searchText) ||
                    contributor.email.includes(searchText)
            )
            .map(getContributorOption) ?? [];

    const memberTypes = Object.keys(MemberType).map((key: string) => ({
        label: t('as', { memberType: t(key as MemberType) }),
        value: key as MemberType
    }));

    const handleInvite = async () => {
        try {
            const isEmail = emailRegex.test(selectedContributor);
            await mutateAsync({
                id: teamId,
                data: {
                    contributorId: isEmail ? undefined : selectedContributor,
                    contributorEmail: isEmail ? selectedContributor : undefined,
                    type: selectedMemberType
                }
            });
            onResolve();
        } catch (err) {
            toastNotifications.error({
                title: t('unknownError'),
                message: t('unknownErrorDescription', {
                    reason: getExceptionDetails(err)?.message
                })
            });
        }
    };

    return (
        <Modal opened={isOpen} title={title} onClose={onReject}>
            <div className="flex w-[560px] flex-col gap-6">
                <div className="flex flex-col items-start gap-2">
                    <span className="text-base w-full font-normal leading-base text-text-ultra-light">
                        {t(
                            'inviteCollaboratorsText',
                            'Users invited to this team can manage requests from companies to use our digital service for [country].'
                        )}
                    </span>
                    {areContributorsLoading && <LoadingAnimation />}
                    {!areContributorsLoading && (
                        <div className="flex w-full flex-row gap-base">
                            <Select
                                className="w-full flex-grow"
                                classNames={{
                                    option: 'p-0'
                                }}
                                data={contributorsList}
                                getCreateLabel={(query) => t('inviteContributorViaEmail', { email: query })}
                                placeholder={t('searchInvite')}
                                renderOption={renderContributorOption}
                                shouldCreate={(query, _data) =>
                                    emailRegex.test(query) &&
                                    (!contributors?.data ||
                                        !contributors.data.some((contributor) => contributor.email === query))
                                }
                                value={selectedContributor}
                                variant={variants.textField.iconDecoration}
                                searchable
                                onChange={(value) => setSelectedContributor(value ?? '')}
                                onCreate={(query) => {
                                    return query;
                                }}
                                onSearchChange={(query) => setSearchText(query)}
                            />
                            <Select
                                className="w-60"
                                data={memberTypes}
                                value={selectedMemberType}
                                variant={variants.textField.iconDecoration}
                                onChange={(value) => setSelectedMemberType(value as MemberType)}
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-row justify-end gap-x-base">
                    <Button variant={variants.button.secondary} onClick={handleReject}>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={!selectedContributor}
                        loading={isPending}
                        variant={variants.button.tertiary}
                        onClick={handleInvite}
                    >
                        {acceptButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export const openInviteModal = create(InviteModal);
