import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequestContext } from './RequestProvider';

export const RequestHeader = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();
    const { request } = useRequestContext();

    return (
        <div className="flex flex-row gap-base border-b border-border-default pb-base">
            <div className="flex flex-grow flex-col">
                <div>{request.companyData.companyName}</div>
                <div className="text-text-light">
                    {t('requestHeaderDescription', {
                        requester: request.companyOnboardingResponsible?.model.displayName,
                        date: new Date(request.createdDate).toDateString()
                    })}
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
};
